jQuery(($) => {
  var ppp = 3; // Post per page
  var pageNumber = 3;

  $(".more-posts.read--more").on("click", function () {
    console.log("clicked");

    var button = $(this);
    var btnText = button.text();
    button.text("Loading...");

    var str =
      "&pageNumber=" +
      pageNumber +
      "&ppp=" +
      ppp +
      "&type=post&action=more_post_ajax";

    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_posts.ajaxurl,
      data: str,
      beforeSend: function (jqXHR) {
        button.text("Loading...");
      },
      success: function (data) {
        if (data) {
          $(".row.news--content").append(data);
          button.text(btnText);
        } else {
          button.hide();
          /* $(".row.news--content").text(
              "No more posts"
            ); */
          console.log("No More Items");
        }
      },
      afterSend: function (jqXHR) {
        button.text("More posts").prev().before(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      },
    });
    pageNumber++;
  });
});
